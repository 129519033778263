.swiper {
  padding-left: 16px ;
  padding-right: 16px ;
}

.swiper-button-prev,
.swiper-button-next {
  color: #B2CAE1 ;
  font-weight: bold ;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0px ;
  right: auto ;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px ;
  left: auto ;
}

@media (orientation:landscape) and (min-width:1025px) {
  div.news_vorschau {
    opacity: 0.3 ;
  }
  div.news_vorschau:hover {
    opacity: 1.0 ;
  }
}
