/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #B2CAE1;
	--ion-color-primary-rgb: 178,202,225;
	--ion-color-primary-contrast: #000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #9db2c6;
	--ion-color-primary-tint: #bacfe4;

	--ion-color-secondary: #4B4B4B;
	--ion-color-secondary-rgb: 0,0,0;
	--ion-color-secondary-contrast: #e1eef4;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #000000;
	--ion-color-secondary-tint: #1a1a1a;

	--ion-color-tertiary: #00519c;
	--ion-color-tertiary-rgb: 42,46,109;
	--ion-color-tertiary-contrast: #e1eef4;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #252860;
	--ion-color-tertiary-tint: #3f437c;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #e1eef4;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

  --ion-font-family:  'OpenSans'!important;
  font-family:  'OpenSans' !important;
  font-size: 16px;

}

h1 {
  color: var(--ion-color-secondary);
  font-size: 35px !important;
  padding-bottom: 15px;
}
h2 {
  color: var(--ion-color-tertiary);
  font-size: 27px !important;
  font-weight: 700 !important;
  word-spacing: 2px;
  padding-top: 10px;
  padding-bottom: 5px;
}
h3 {
  color: #00519c;
  font-size: 22px !important;
  font-weight: 700 !important;
  word-spacing: 2px;
  padding-top: 10px;
  padding-bottom: 5px;
}
h4 {
  color: var(--ion-color-secondary);
  font-size: 18px !important;
  word-spacing: 2px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 700 !important;
}

p {
  font-size: 16px !important;
  color:var(--ion-color-secondary);
  padding-bottom: 5px;
  line-height: 1.5;
}

a {
  color:#00519C;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

ion-card-header {
  color: var(--ion-color-secondary)
}

ion-card-title {
  color: var(--ion-color-secondary);
  font-size: 20px;
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: normal;
  src: url('/public/assets/fonts/OpenSans.ttf');
}

/* TABLE */
table {
  background-color: #B3CAE1;
  width: 100%;
  text-align: left;
}
td, th {
  border: 1px solid #FFFFFF;
  padding: 5px 5px;
}
tbody td {
  font-size: 16px;
}
tr:nth-child(even) {
  background: #E1EEF4;
}
thead {
  background: #00519C;
  border-bottom: 2px solid #444444;
}
thead th {
  font-size: 16px;
  font-weight: normal;
  color: #FFFFFF;
}

/* Trennlinie */
hr {
  background-color: var(--ion-color-primary);
  width:100%;
  border:0px;
  height:1px;
}

p {
  width:90%;
}

@media (min-width: 1530px) {
  ion-app {
    width: 1530px;
    margin: auto;
    position: relative;
  }

  .scroll-content {
    overflow-y:auto;
  }}

  body {
    background: linear-gradient(180deg, var(--ion-color-primary) 97px, var(--ion-color-light) 97px);
  }
