img.fav_dgti {
  cursor: pointer ;
  filter: grayscale(1) ;
  max-height: 30px ;
  min-height: 30px ;
  max-width: 75px ;
  min-width: 75px ;
}

img.fav_dgti:hover {
  filter: grayscale(0) ;
}

img.fav_greifswald {
  filter: grayscale(1) ;
  max-height: 30px ;
  min-height: 30px ;
  max-width: 115px ;
  min-width: 115px ;
}

img.fav_greifswald:hover {
  filter: grayscale(0) ;
}

img.fav_smartparking {
  cursor: pointer ;
  filter: grayscale(1) ;
  max-height: 40px ;
  min-height: 40px ;
  max-width: 219px ;
  min-width: 219px ;
  padding-right: 16px ;
}

img.fav_smartparking:hover {
  filter: grayscale(0) ;
}

img.fav_stoerungen {
  filter: grayscale(1) ;
  max-height: 50px ;
  min-height: 50px ;
  max-width: 125px ;
  min-width: 125px ;
}

img.fav_stoerungen:hover {
  filter: grayscale(0) ;
}
